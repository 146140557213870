import { VisuallyHidden } from 'reakit/VisuallyHidden';

import { Link, LinkProps } from '../link';
import KindestLogo from './assets/kindest-logo.svg';

type Props = {
  href?: LinkProps['href'] | false
  variant?: 'dark' | 'light'
}

export const Logo = ({ href = '/', variant = 'dark' }: Props) => {
  if (href)
  {
    return (
      <Link href={href}>
        <a>
          <VisuallyHidden>Kindest Logo</VisuallyHidden>
          <KindestLogo
            style={{ color: variant === 'dark' ? '#2F2F4B' : '#fff' }}
          />
        </a>
      </Link>
    )
  }

  return <KindestLogo />
}
