import { CenterLayout } from '~/layouts/center-layout'
import { SEO } from '~/components/seo'

export const NotFound = () => {
  return (
    <>
      <SEO title="Page Not Found" />
      <CenterLayout
        heading="Oops, this page isn't available"
        subheading="You may have mistyped the address or the page may have moved."
      />
    </>
  )
}

export default NotFound
