import { GetServerSideProps } from 'next'
import dynamic from 'next/dynamic'
import { SEO } from '~/components/seo'
import { env } from '~/config/env'
import {
  PageBySlugDocument,
  PageBySlugQuery,
  PageBySlugQueryVariables,
} from '~/graphql/page-by-slug'
import { withStripe } from '~/hocs/with-stripe'
import { initializeApollo } from '~/hooks/use-apollo'
import { FundraisingPage } from '~/pages/fundraising-page'
import { NotFound } from '~/pages/not-found'
import { PageType } from '~/types/graphql'

const DonationPage = dynamic(import('~/pages/donation-page'))

type Props = {
  page?: PageBySlugQuery['pageBySlug']
  hasDonations?: boolean
  fundraiser?: PageBySlugQuery['pageBySlug']['fundraisers'][0]
}

const PageRouter = ({ page, hasDonations, fundraiser }: Props) => {
  if (!page) {
    return <NotFound />
  }

  const renderPage = () => {
    if (page.type === PageType.DonationPage) {
      return <DonationPage page={page} fundraiser={fundraiser} />
    }

    return <FundraisingPage page={page} hasDonations={hasDonations!} />
  }

  return (
    <>
      <SEO
        title={page.title}
        description={`Click here to support ${page.nonprofit.name}.`}
        image={page.type === PageType.FundraisingPage ? page.imageUrl! : null}
        url={`${env.baseUrl}/${page.slug}`}
        hideKindest
      />
      {renderPage()}
    </>
  )
}

export default withStripe(PageRouter)

export const getServerSideProps: GetServerSideProps<Props> = async context => {
  const apolloClient = initializeApollo()

  try {
    const { data } = await apolloClient.query<
      PageBySlugQuery,
      PageBySlugQueryVariables
    >({
      query: PageBySlugDocument,
      variables: {
        slug: context.query.slug as string,
      },
    })

    return {
      props: {
        page: data!.pageBySlug,
        hasDonations: data!.community.pageInfo.totalItems !== 0,
      },
    }
  } catch (error: any) {
    return {
      props: {},
    }
  }
}
