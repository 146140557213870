import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { NextPage } from 'next'

import { env } from '../config/env'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripe = loadStripe(env.stripeKey)
const options: StripeElementsOptions = {
  fonts: [
    {
      family: 'Graphik',
      weight: '300',
      src: `url(${env.baseUrl}/fonts/graphik-regular.woff)`,
      style: 'normal',
    },
  ],
}

export const withStripe = <T extends {}>(PageComponent: NextPage<T>) => {
  const WithStripe: NextPage<T> = pageProps => {
    return (
      <>
        <Elements stripe={stripe} options={options}>
          <PageComponent {...pageProps} />
        </Elements>
      </>
    )
  }

  return hoistNonReactStatics(WithStripe, PageComponent)
}
