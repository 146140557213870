import { Box, Flex } from '~/components/layout'
import { Logo } from '~/components/logo'
import { Heading, Text } from '~/components/typography'
import { colors } from '~/styles/colors'

import { Header, Wrapper } from './style'

type Props = {
  icon?: React.ReactNode
  heading: string
  subheading?: string
  children?: React.ReactNode
  headerLink?: React.ReactNode
  maxWidth?: 448 | 592
}

export const CenterLayout = ({
  icon,
  heading,
  subheading,
  children = null,
  headerLink = null,
  maxWidth,
}: Props) => {
  return (
    <>
      <Header>
        <Logo />
        {headerLink}
      </Header>
      <Wrapper>
        <Box width="100%" maxWidth={maxWidth} style={{ textAlign: 'center' }}>
          {icon && (
            <Flex justifyContent="center" marginBottom={20}>
              {icon}
            </Flex>
          )}
          <Heading textAlign="center" marginBottom={4}>
            {heading}
          </Heading>
          {subheading && (
            <Text marginBottom={24} textAlign="center" color={colors.slateGray}>
              {subheading}
            </Text>
          )}
          {children}
        </Box>
      </Wrapper>
    </>
  )
}
