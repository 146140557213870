import styled from 'styled-components'
import { media } from '~/styles/media'

export const Wrapper = styled.div({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [media.maxSmall]: {
    padding: '0 20px',
  },
})

export const Header = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  padding: '28px 32px ',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
